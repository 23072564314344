import { Params } from "@angular/router";


export class PagingParamsManager
{
    maxPageNo: number = 0;
    pageNo: number = 1;
    pageSize: number = 20;
    resumeKeys: string[] = [];

    constructor(pageSize: number) {
        this.pageSize = pageSize;
    }

    public init(params: Params) {
        this.maxPageNo = parseInt(params['maxPageNo'] || 0);
        this.pageNo = parseInt(params['pageNo'] || 1);
        this.pageSize = parseInt(params['pageSize'] || this.pageSize);
        this.resumeKeys = params['resumeKeys'] ? JSON.parse(decodeURI(params['resumeKeys'])) : [];
    }

    public toQueryParams(): Params {
        return {
            maxPageNo: this.maxPageNo,
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            resumeKeys: encodeURI(JSON.stringify(this.resumeKeys))
        };
    }

    public updateFromResults(page: number, resumeKey?: string) {
        this.pageNo = page;
        if (!resumeKey) { // we hit our maxPageno
            this.maxPageNo = page;
        }
        else if (this.maxPageNo <= page) {
            this.maxPageNo = 0;
        }
        //  WARNING:  Complex logic ahead.
        //  If the resumeKey passed in matches the resume key
        //  we already have for the next page, then we don't need
        //  to do anything.
        if ( resumeKey !== this.resumeKeyFor(page+1)) {
            //  First, we cut our list of resume keys to only those
            //  up to this page.
            this.resumeKeys = this.resumeKeys.slice(0, page - 1);
            //  Now, if we have a resumeKey, we add it to the list.
            if (resumeKey) {
                this.resumeKeys.push(resumeKey);
            }
        }
        if (this.maxPageNo > this.pageCount()) {
            this.maxPageNo = 0;
        }
    }

    public pageCount(): number {
        return this.resumeKeys.length + 1;
    }

    public resumeKeyFor(page: number): string|undefined {
        return (page > 1 && page <= this.resumeKeys.length + 1)
            ? this.resumeKeys[page-2]
            : undefined;
    }

    pageNumberArray(): number[] {
        return this.generateRange(this.pageCount());
    }

    generateRange(count: number): number[] {
        return Array(count).fill(0).map((_, index) => index + 1);
    }
      
}