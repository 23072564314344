<mat-sidenav-container class="border-right">    
  <mat-sidenav *ngIf="user" class="bg-light">
    <div *ngIf="showCloseButton" class="list-group-item bg-light" style="text-align: right; padding: 0;">
      <button class="btn btn-link btn-sm gs-side-menu-close-button" (click)="closeNav(1)" alt="Close side menu" title="Close side menu"> 
        <i class="fa-solid fa-angles-left fa-sm"></i>
      </button>
    </div>
    <ul class="list-group list-group-flush">
      <app-side-bar-menu-item *ngFor="let item of itemMenu" [item]="item" [userRoles]="userRoles"></app-side-bar-menu-item>     
    </ul>
  </mat-sidenav>
  <mat-sidenav-content class="gs-main-content">
    <div class="content">
      <ng-container
        *ngIf="template"
        [ngTemplateOutlet]="template"
      ></ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
