import { ErrorHandler } from "@angular/core";
import { TokenExpError } from "./token-exp";

export class CoreErrorHandler implements ErrorHandler {
    handleError(error: any) {
      if(!(error instanceof TokenExpError)){
        console.error('Uncaught error: ', error);
      }
    }
  }
  