import { Component, Input } from '@angular/core';
import { ItemMenu } from '../../models/config/item-menu';
import { Router } from '@angular/router';
import { UserGrantService } from '../../services/user-grant/user-grant.service';

@Component({
  selector: 'app-side-bar-menu-item',
  templateUrl: './side-bar-menu-item.component.html',
  styleUrls: ['./side-bar-menu-item.component.scss'],
})
export class SideBarMenuItemComponent {
  @Input() item: ItemMenu | undefined;

  @Input() userRoles: string[] | undefined;

  constructor(
    private router: Router,
    private userGrantService: UserGrantService
  ) {}

  showItem(): boolean {
    return this.item?.roles.includes('*') || this.validateUserRoles();
  }

  validateUserRoles(): boolean {
    // Validate first if the user is admin of the system.
    let isAdmin = this.userGrantService.isAdmin();

    return (
      isAdmin ||
      (this.userRoles?.some((r) => this.item?.roles.includes(r)) ?? false)
    );
  }
}
