import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagingParamsManager } from '../../models/classes/paging-params-manager';

@Component({
  selector: 'app-page-numbers',
  templateUrl: './page-numbers.component.html',
  styleUrls: ['./page-numbers.component.scss']
})
export class PageNumbersComponent {
    @Input() pagingParamsManager: PagingParamsManager = new PagingParamsManager(20);
    @Output() search: EventEmitter<number> = new EventEmitter<number>();

    handleClick(page: number, event?: Event): void {
        if (event) event.preventDefault();
        this.search.emit(page);
    }
}
