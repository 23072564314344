import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators'
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptorService implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService) {

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinner.show();

    const customReq = request.clone({

    });
    return next.handle(customReq)
      .pipe(catchError((err) => {
        console.warn(err)
      this.spinner.hide();
      return  throwError(err);
    }))
    .pipe(map<HttpEvent<unknown>, any>((evt: HttpEvent<any>, any) => {
      if (evt instanceof HttpResponse) {
        this.spinner.hide();
      }
      return evt;
    }));;
  }
}
