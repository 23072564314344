export class TokenExpError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'TokenExpError';
        this.message = message;
        this.stack = (<any>new Error()).stack;
    }

    override toString() {
        return this.name + ': ' + this.message;
    }
}