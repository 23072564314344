/**
 * Enum that manages all the states that a side menu bar
 * can has depending the view port and the user interaction
 */
export enum SideNavBarClosedStates {
    /**
     * Indicates that the side menu is open.
     */
    NOT_CLOSED = 0,
    /**
     * Indicates that the side menu is closed in a normal port view.
     */
    CLOSED = 1,
    /**
     * Indicates that the side menu is closed by the toggle generated by
     * the response nav bar when a screen is small.
     */
    CLOSED_RESPONSIVE = 2
}