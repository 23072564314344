export const environment = {
    production: false,
    env: 'uat',
    transApi: `https://trans.uat.gravystackapp.com/v2-admin`,
    usersApi: `https://user-management.uat.gravystackapp.com/v2`,
    customerPaymentsApi: `https://customer-payments.uat.gravystackapp.com/v2`,
    bankingApi: `https://banking.uat.gravystackapp.com/v2`,
    userPortalSessionKey: "AG_USR",
    rolPortalSessionKey: "AG_USR_ROLES",
    userTokenKey: "AG_TOKEN",
    userSessionChallenge:"AG_SESSION_CHALLENGE",
    synapseDashboardHostname:"uat-dashboard.synapsefi.com",
    amplitudeDashboardHostname:"app.amplitude.com",
    amplitudeDashboardProjectId:"402975",
    agentRoleId: '9d0c83c0-3dd6-46de-b175-2f3d2da46c2c',
    managerRoleId: '6818cf2c-b782-40f2-8d4c-1d53438665c0',
    administratorRoleId: '195936d7-46f7-4e6e-bed2-25f7f6d07d51',
};
