import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session/user-session.service';
import { environment } from 'src/environments/environment';
import { SideNavBarClosedStates } from '../../models/enums/nav-bar-states';
import { UserGrantService } from '../../services/user-grant/user-grant.service';

@Component({
  selector: 'gs-dashboard-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit{

  closedStates = SideNavBarClosedStates;

  sideNavState: SideNavBarClosedStates = SideNavBarClosedStates.NOT_CLOSED;

  @Input() template: TemplateRef<any> | undefined;

  isLoggedIn = false;
  appName = "";
  username: string | null;

  isProd = environment.production;
  env = environment.env;

  constructor(private router: Router, private userSessionService: UserSessionService, private userGrantService: UserGrantService) {
    
    this.username = null;
    
  }

  ngOnInit(){
    this.getUserData();
  }
  
  /**
   * Gets the current user information and sets the local variables to show his/her name.
   */
  getUserData(): void {
    const userData = this.userSessionService.getCurrentUser();
    this.username = userData?.user || null;
    this.isLoggedIn = (this.username) ? true : false;    
  }

  login() {
    console.log('clicking login');
    this.router.navigateByUrl('/auth/login');
  }

  logOut() {
    this.userSessionService.logOutUser();
    this.userGrantService.logOutUser();
    this.isLoggedIn = false;    
  }

  goToUserList() {
    this.router.navigate(["/users/user-list"]);
  }

  goToReturnedTransactionsList() {
    this.router.navigate(["/transactions/returned"]);
  }

  goToOverdrawnAccountsList() {
    this.router.navigate(["/transactions/accounts/overdrawn"]);
  }

  hideSideNav(closedType: SideNavBarClosedStates): void {
    this.sideNavState = closedType;
  }  

  showSideNav(): void {
    this.sideNavState = SideNavBarClosedStates.NOT_CLOSED;    
  }

  sideBarFromToggle(): void {
    if(this.sideNavState != SideNavBarClosedStates.NOT_CLOSED){
      this.showSideNav();

      return;
    }

    this.hideSideNav(SideNavBarClosedStates.CLOSED_RESPONSIVE);
  }
}
