<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
    <div class="container-fluid">
      <div *ngIf="sideNavState == closedStates.CLOSED">
        <!-- Collapse button -->
        <button
          class="navbar-toggler"
          type="button"          
          style="display: inline-block"          
          aria-label="Toggle side menu"
          (click)="showSideNav()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="navbar-brand">
        <a routerLink="/user-list">
          <img id="gs-log-header" src="/assets/images/nav-bar-logo.svg" />
        </a>
        <span *ngIf="!isProd" class="badge bg-success gs-dev-icon">{{env.toUpperCase()}}</span>
      </div>      
      
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="sideBarFromToggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <ul class="nav navbar-nav navbar-right">
          <li *ngIf="isLoggedIn" class="dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-user-circle fa-fw"></i> {{ username }}
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- <li class="border-bottom">
                <span class="dropdown-item"
                  ><i class="fas fa-user-cog"></i> Users Management</span
                >
              </li>
              <li class="divider"></li>
              
              <li>
                <a (click)="goToUserList()" class="dropdown-item"
                  ><i class="fa fa-users fa-fw"></i> Users List</a
                >
              </li>
              -->
              <li class="divider"></li>
              <li>
                <a (click)="logOut()" class="dropdown-item"
                  ><i class="fa fa-sign-out fa-fw"></i> Logout</a
                >
              </li>
            </ul>
          </li>
          <li *ngIf="isLoggedIn === false">
            <a title="Sign In" role="button" (click)="login()"> Sign In</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<div>
  <app-side-bar-menu
    [user]="username ?? undefined"
    [template]="template"
    [updateSideNavState] ="sideNavState"
    (closedSideNav)="hideSideNav($event)"
  ></app-side-bar-menu>
</div>
