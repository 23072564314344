import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-module/auth-module.module').then((m) => m.AuthModule),
  },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'users',
    loadChildren: () =>
      import('./user-admin-module/user-admin-module.module').then(
        (m) => m.UserAdminModuleModule
      ),
  },
  {
    path: 'dashboard-users',
    loadChildren: () =>
      import(
        './dashboard-user-admin-module/dashboard-user-admin-module.module'
      ).then((m) => m.DashboardUserAdminModuleModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import(
        './reports-module/reports-module.module'
      ).then((m) => m.ReportsModuleModule),
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import(
        './transaction-monitor-module/transaction-monitor-module.module'
      ).then((m) => m.TransactionMonitorModuleModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
