<div class="gs-main-paginator">    
    <ng-container *ngIf="pagingParamsManager.pageCount() as pageCount">
        <ul class="pagination">
            <li class="page-item disabled">
                <a class="page-link" href="#" tabindex="-1">Pg.</a>
              </li>
            <li class="page-item" *ngFor="let number of pagingParamsManager.pageNumberArray()" [ngClass]="{'active': number === pagingParamsManager.pageNo}">
                <a href="#" (click)="handleClick(number, $event)" class="page-link">
                    <ng-container *ngIf="pagingParamsManager.maxPageNo === 0 && number === pagingParamsManager.pageCount(); else elseBlock">
                        Next
                    </ng-container>
                    <ng-template #elseBlock>
                        {{ number }}
                    </ng-template>
                </a>
            </li>
        </ul>        
    </ng-container>
</div>
