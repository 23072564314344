import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { UserSessionService } from './services/user-session/user-session.service';
import { CoreErrorHandler } from './models/errors/core-error-handler';
import { PageNumbersComponent } from './components/page-numbers/page-numbers.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SideBarMenuComponent } from './components/side-bar-menu/side-bar-menu.component';
import { RouterModule } from '@angular/router';
import { SideBarMenuItemComponent } from './components/side-bar-menu-item/side-bar-menu-item.component';

@NgModule({
  declarations: [
    HeaderMenuComponent,
    PageNumbersComponent,
    SideBarMenuComponent,
    SideBarMenuItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule, 
    MatIconModule, 
    MatDividerModule,
  ],
  exports: [HeaderMenuComponent, PageNumbersComponent, SideBarMenuComponent], 
  providers: [UserSessionService, {provide: ErrorHandler, useClass: CoreErrorHandler}]
})
export class CoreModuleModule { }
