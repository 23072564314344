import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserData, UserMetadata } from '../../models/auth/user-metadata';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  constructor(private router: Router){

  }

  setAuthUserMetadata(userMetadata: UserMetadata): void {
    if (userMetadata) {

      const userData: UserData = {
        id: userMetadata.id, 
        user: userMetadata.user
      }

      sessionStorage.setItem(
        window.btoa(environment.userPortalSessionKey),
        window.btoa(JSON.stringify(userData))
      );

      if (userMetadata.roles) {
        sessionStorage.setItem(
          window.btoa(environment.rolPortalSessionKey),
          window.btoa(JSON.stringify(userMetadata.roles))
        );
      }

      if (userMetadata.token) {
        this.setToken(userMetadata.token);
      }
    }
  }


  setAuthSessionChallenge(session: string){
    if(session){
      sessionStorage.setItem(
        window.btoa(environment.userSessionChallenge),
        window.btoa(session)
      );
    }
  }

  setToken(token: string): void {
    sessionStorage.setItem(
      window.btoa(environment.userTokenKey),
      window.btoa(token)
    );
  }

  getToken(): string | null {
    const token = sessionStorage.getItem(window.btoa(environment.userTokenKey));

    return token ? window.atob(token) : null;
  }

  getCurrentUser(): UserData | null {
    const usr = sessionStorage.getItem(
      window.btoa(environment.userPortalSessionKey)
    );

    return usr ? JSON.parse(window.atob(usr)) as UserData : null;
  }

  getChallengeSession(): string | null {
    const session = sessionStorage.getItem(window.btoa(environment.userSessionChallenge));

    return session ? window.atob(session) : null;
  }

  getCurrentUserRoles(): string[] | null {
    const roles = sessionStorage.getItem(
      window.btoa(environment.rolPortalSessionKey)
    );

    return roles ? JSON.parse(window.atob(roles)) : null;
  }

  logOutUser(isExpiredSession: boolean = false): void {
    sessionStorage.removeItem(window.btoa(environment.userPortalSessionKey));
    sessionStorage.removeItem(window.btoa(environment.rolPortalSessionKey));
    sessionStorage.removeItem(window.btoa(environment.userTokenKey));

    if(isExpiredSession){
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url, sessionExpired: isExpiredSession }});
      return; 
    }    

    this.router.navigate(['/auth/login']);
  }

  isLoggedIn(): boolean {
    const user = this.getCurrentUser();

    return user?.user ? true : false;
  }
}
