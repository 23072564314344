import { Injectable } from '@angular/core';
import { UserSessionService } from '../user-session/user-session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserGrantService {
  adminRole = environment.administratorRoleId;

  userRoles: string[] = [];

  constructor(private userSessionService: UserSessionService) {
    this.getUserRoles();
  }

  logOutUser(): void {
    this.userRoles = [];
  }

  getUserRoles(): void {
    this.userRoles = this.userSessionService.getCurrentUserRoles() ?? [];
  }

  validateRole(role: string): boolean {
    let isAdmin = this.isAdmin();

    return isAdmin || (this.userRoles.includes(role) ?? false);
  }

  isAdmin(): boolean {
    if (this.userRoles.length == 0) {
      console.log('roles not set, fill it');
      this.getUserRoles();
    }

    return this.userRoles.includes(this.adminRole) ?? false;
  }
}
