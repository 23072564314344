import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter } from 'rxjs/operators';
import { ItemMenu } from '../../models/config/item-menu';
import { UserSessionService } from '../../services/user-session/user-session.service';
import { environment } from 'src/environments/environment';
import { SideNavBarClosedStates } from '../../models/enums/nav-bar-states';

@UntilDestroy()
@Component({
  selector: 'app-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss'],
})
export class SideBarMenuComponent {
  agentRoleId = environment.agentRoleId ?? '';
  managerRoleId = environment.managerRoleId ?? '';
  
  showCloseButton: boolean = true;

  itemMenu: ItemMenu[] = [
    {
      id: 'accountManagementRoot',
      displayName: 'Account Management',
      roles: ['*'],
      iconClass: 'fas fa-user-cog',
      children: [
        {
          id: 'searchUsers',
          displayName: 'Search Users',
          route: '/users/user-list',
          roles: ['*'],
        },
      ],
    },
    {
      id: 'fraudPrevention',
      displayName: 'Fraud Prevention',
      roles: [this.agentRoleId, this.managerRoleId],
      iconClass: 'fas fa-money-check-alt',
      children: [
        {
          id: 'returnedTransactions',
          displayName: 'Returned Transactions',
          route: '/transactions/returned',
          roles: [this.agentRoleId, this.managerRoleId],
        },
        {
          id: 'overdrawnAccounts',
          displayName: 'Overdrawn Accounts',
          route: '/transactions/accounts/overdrawn',
          roles: [this.agentRoleId, this.managerRoleId],
        },
      ],
    },
    {
      id: 'reports',
      displayName: 'Reports',
      roles: [this.agentRoleId, this.managerRoleId],
      iconClass: 'fa-solid fa-table-list',      
      children: [
        {
          id: 'subscriptionsReport',
          displayName: 'Subscriptions',
          route: '/reports/subscriptions',
          roles: [this.agentRoleId, this.managerRoleId],
        },        
      ],
    },
    {
      id: 'administratorsRoot',
      displayName: 'Administration',
      roles: [],
      iconClass: 'fa-solid fa-screwdriver-wrench',
      children: [
        {
          id: 'newDashboardUser',
          displayName: 'Add New Users',
          route: '/dashboard-users/user',
          roles: [],
        },
        {
          id: 'dashboardUserList',
          displayName: 'Dashboard User List',
          route: '/dashboard-users/user-list',
          roles: [],
        },
      ],
    },
  ];

  @Input() template: TemplateRef<any> | undefined;

  @Input() user: string | undefined;

  private _updateSideNavState: SideNavBarClosedStates = SideNavBarClosedStates.CLOSED;
  /**
   * Indicates that the command to open/close the side menu bar.
   * 
   */
  @Input()
  public get updateSideNavState(): SideNavBarClosedStates {
    return this._updateSideNavState;
  }
  public set updateSideNavState(value: SideNavBarClosedStates) {
    if (value == 0) {
      this.openNav();
    }

    if (value != 0) {
      this.closeNav(value);
    }
    this._updateSideNavState = value;
  }

  /**
   * Output that indicates the parent component that the side menu bar is closed
   * and the state of the action. (SideNavBarClosedStates)
   */
  @Output() closedSideNav: EventEmitter<SideNavBarClosedStates> =
    new EventEmitter<SideNavBarClosedStates>();

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  userRoles: string[];

  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    private userSession: UserSessionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.userRoles = this.userSession.getCurrentUserRoles() ?? [];
  }

  ngAfterViewInit() {
    // Manages the port view to auto hide the side menu bar.
    this.observer
      .observe(['(max-width: 991px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {        
        if (res.matches && this.sidenav) {
          this.sidenav.mode = 'over';
          this.closeNav(SideNavBarClosedStates.CLOSED_RESPONSIVE);

          this.showCloseButton = false;
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();

          this.showCloseButton = true;
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });

    this.changeDetectorRef.detectChanges();
  }

  closeNav(closedType: SideNavBarClosedStates): void {
    if (this.sidenav) {
      this.sidenav.close();
      this.closedSideNav.emit(closedType);
    }
  }

  openNav(): void {
    this.sidenav?.open();
  }
}
