<li *ngIf="item && showItem()">
  <a
    *ngIf="item.children && item.children.length > 0; else singleItem"
    href="#{{ item.id }}"
    class="list-group-item list-group-item-action list-group-item-dark dropdown-toggle"
    data-bs-toggle="collapse"
    aria-expanded="true"
    ><i *ngIf="item.iconClass" class="{{ item.iconClass }}"></i>
    {{ item.displayName }}</a
  >
  <ng-template #singleItem>
    <a
      routerLink="{{ item.route }}"
      class="list-group-item list-group-item-action bg-light"
      >{{ item.displayName }}</a
    >
  </ng-template>
</li>
<ul *ngIf="item && showItem()"  class="collapse show list-unstyled" id="{{ item.id || '' }}">
  <app-side-bar-menu-item
    *ngFor="let child of item?.children"
    [item]="child"
    [userRoles]="userRoles"
  ></app-side-bar-menu-item>
</ul>
